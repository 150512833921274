<template>
  <div class="page-head" @click="isShow = false;" @mouseleave="isShow = false;">
    <div style="display: flex;justify-content: flex-start;align-items: center;height: 100%;">
      <div class="head-left">
        <img @click="handleGoTo(headList[0])" :src="siteLogo ? this.DEEHOWBASEURL + '/sys/api/v1/getView' + siteLogo : '../../assets/img/other/logo.png'"/>
        <div @click="handleGoTo(headList[0])" class="head-title">{{siteName ? siteName : 'EDM研发协同设计管理平台'}}</div>
        <div style="display: flex;justify-content: center;align-items: center;" v-if="showFlag">
          <i @click="toggleSideBar" v-if="sidebar.opened" class="el-icon-more toggleSidebar" style=""></i>
          <i @click="toggleSideBar" v-else class="el-icon-more-outline toggleSidebar" style=""></i>
        </div>
      </div>
      <div class="head-middle">
        <ul>
          <li v-for="(item,index) in headList" :key="index"
              :class="item.name === edmHeader.nowTab ? 'istrue' : ''"
              @click="handleGoTo(item)">
            <img class="item-img" :src="require(`../../assets/img/layout/top/${item.meta.icon}.png`)"/>
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="head-right">
      <!--      <div class="head-input" v-show="screen.width > 1295">
              <input placeholder="输入关键字检索数据" />
              <div class="icon"><i class="el-icon-search"></i></div>
            </div>-->
      <!--      <div class="lingdang">
              <img src="../../assets/img/other/lingdang.png" />
              <div class="message">{{ count }}</div>
            </div>-->
      <div class="yonghu">
        <img src="../../assets/img/other/user.png"/>
      </div>
      <div class="right-select" @mouseenter="handleShow">
        {{ userDetail.userName }}<i class="el-icon-caret-bottom"></i>
      </div>
    </div>

    <el-collapse-transition>
      <div class="head-select" v-if="isShow">
        <!-- <div class="h-s">个人信息</div> -->
        <div class="h-s bor" @click="handleEditPwd">
          <div class="icon-edit2"></div>
          <div>修改密码</div>
        </div>
        <div class="h-s" @click="handleExit">
          <div class="icon-exit"></div>
          <div>退出登录</div>
        </div>
      </div>
    </el-collapse-transition>
    <el-dialog title="个人密码设置" width="30%" :close-on-click-modal="false" v-dialogDrag :visible.sync="dialogSetPassword" size="tiny" :modal="false">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="oldPassword">
          <el-input type="password" v-model="form.oldPassword" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
          <el-input type="password" v-model="form.password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" prop="pass1">
          <el-input type="password" v-model="form.pass1" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetPassword = false">取消</el-button>
        <el-button type="primary" @click="setpassword">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {exitApp, getToken} from "@/utils/auth";
import {
  setPassword,
} from "@/api/library/login";

export default {
  name: "Header",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("确认密码输入错误，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      headList: [
        {
          name: "首页",
          path:'/indexSite',
          meta:{
            icon:"indexSite",
            title:"首页"
          }
        },
      ],
      isShow: false,
      count:0,
      dialogSetPassword:false,
      form: {
        oldPassword: "",
        password: "",
        pass1: "",
      },
      formLabelWidth: "80px",
      rules: {
        oldPassword: [
          {required: true, message: "请输入原密码", trigger: "blur"},
        ],
        password: [
          {required: true, message: "请输入新密码", trigger: "blur"},
        ],
        pass1: [
          {
            required: true,
            message: "请确保两次输入的密码一致",
            trigger: "blur",
            validator: validatePass,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(["userDetail", "permission_addRoutes", "activeModel", "edmHeader","sidebar","edmRoutes","navigationList","siteName","siteLogo"]),
    showFlag() {
      if (this.$route.path !== '/indexSite' && !this.$route.fullPath.includes("controlCenter")) {
        return true
      } else {
        return false;
      }
    },
    screen(){
      return this.$store.state.app.screen;
    },
  },
  mounted() {
    // this.initActiveModel();
    this.headList = [
      {
        name: "首页",
        path:'/indexSite',
        meta:{
          icon:"indexSite",
          title:"首页"
        }
      },
    ]
    let end = null;
    this.edmRoutes.forEach(v => {
      if (v.parentId === '0') {
        if (v.name === "控制中心") {
          end = v;
        } else {
          this.headList.push(v);
        }
      }
    })
    if (end) {
      this.headList.push(end);
    }
    let token = getToken();
    if (token) {
      this.$store.dispatch("user/GetUserInfo").then((res) => {
        //
      });
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    handleShow() {
      this.isShow = true;
    },
    handleEditPwd() {
      this.form = {
        oldPassword: "",
        password: "",
        pass1: "",
      };
      this.dialogSetPassword = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      });
    },
    setpassword() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 数据检验通过进行修改
          setPassword(this.form)
              .then((response) => {
                switch (response.httpCode) {
                  case 200:
                    this.$alert("用户密码修改成功", "系统提示", {
                      type: "success",
                      callback: () => {
                        this.dialogSetPassword = false;
                        this.handleExit()
                      },
                    });
                    break;
                  default:
                    this.$alert(response.msg, "系统提示", {
                      type: "error",
                      callback: () => {
                        this.dialogSetPassword = false;
                      },
                    });
                    break;
                }
              })
              .catch(() => {
              });
        }
      });
    },
    handleExit() {
      exitApp();
      this.isShow = false;
    },
    handleGoTo(item) {
      if (item.path === "/indexSite") {
        return this.$router.push(item.path);
      }
      let path = '';
      let child = item.children;
      if (child && child.length > 0) {
        let route = child[0].children;
        if (route && route.length > 0) {
          path = route[0].path;
        }
      }
      if (this.navigationList.length > 0) {
        let deepNavigationList = this.$deepCopy(this.navigationList);
        let routerObj = deepNavigationList.filter(v => v.parentId === item.id).find(a => a.isEnd);
        if (routerObj) {
          this.$router.push(routerObj.url);
          return;
        }
      }
      if (path) {
        if (path[0] !== '/') {
          path = '/' + path;
        }
        this.$router.push(path);
      }
    },
    initActiveModel() {
      let arr = [];
      this.permission_addRoutes.filter(r => {
        arr.push(r.meta.menuFrom)
      })
      let list = Array.from(new Set(arr));
      this.modelList = list;
      if (!this.activeModel) {
        const {meta} = this.$route
        this.$store.dispatch("user/setActiveModel", meta.menuFrom).then(() => {
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.istrue {
  background: #2c76fe;
  font-weight: 400;
}

.toggleSidebar {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
  color: #fff;
}

.page-head {
  width: 100%;
  height: $navbarHeight;
  background: $headBgColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;

  .head-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img {
      height: 26px;
      margin-right: 10px;
    }

    .head-title {
      color: #fff;
      font-size: 1.5rem;
      letter-spacing: 2px;
    }
  }

  .head-middle {
    height: 100%;
    margin-left: 4px;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      height: 100%;

      li {
        margin: 0;
        cursor: pointer;
        height: 100%;
        min-width: 80px;
        padding: 0 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
          height: 24px;
          width: 24px;
          margin-right: 5px;
        }

        &:hover {
          background: #3c74f7;
          font-weight: 400;
        }
      }
    }
  }

  .head-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    img {
      height: 20px;
      margin-right: 5px;
    }

    .right-select {
      color: #fff;
      font-size: 13px;
      cursor: pointer;
    }
    .lingdang{
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .message{
        position: absolute;
        top: 8px;
        right: 2px;
        background-color: #d70505;
        width: 10px;
        height: 10px;
        font-size: 10px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }
    }

    .yonghu {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #ffffff;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin: 0;
        height: 18px;
      }
    }

    .head-input{
      width: 19rem;
      height: 30px;
      margin-right: 10px;
      display: flex;
      position: relative;
      input{
        width: 100%;
        height: 100%;
        border: none;
        color: #fff;
        //opacity: 0.5;
        padding-left: 5px;
        background-color: rgba(255,255,255,0.5);
        &:focus{
          border: none;
          outline: 0;
        }
        /* WebKit browsers */
        &::-webkit-input-placeholder {
          color: #fff;
        }
        /* Mozilla Firefox 4 to 18 */
        &:-moz-placeholder {
          color: #fff;
        }
        /* Mozilla Firefox 19+ */
        &::-moz-placeholder {
          color: #fff;
        }
        /* Internet Explorer 10+ */
        &:-ms-input-placeholder {
          color: #fff;
        }
      }
      .icon{
        color: #fff;
        display: flex;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .head-select {
    position: absolute;
    top: 45px;
    right: 20px;
    z-index: 10000;
    box-shadow: 4px 4px 5px #eee;;

    .bor {
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
    }

    .h-s {
      padding: 0 10px;
      height: 40px;
      background: #ffffff;
      width: 100px;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-edit2 {
        width: 15px;
        height: 15px;
        background-image: url("../../assets/img/layout/edit1.png");
        background-size: 100%;
        margin-right: 5px;
      }
      .icon-exit {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        background-image: url("../../assets/img/layout/exit1.png");
        background-size: 100%;
      }

      &:hover {
        background: #f5f7fa;
        color: #3c74f7;
        .icon-exit {
          background-image: url("../../assets/img/layout/exit.png");
        }
        .icon-edit2{
          background-image: url("../../assets/img/layout/edit.png");
        }
      }

      &:active {
        background: #eee;
      }
    }
  }
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 18px;
}
</style>
