import Cookies from 'js-cookie';
import { querySiteConfig, updateSiteName } from "@/api/center/siteConfig";

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  language: "zh",
  size: Cookies.get('size') || 'medium',
  screen:{
    width:0,
    height:0
  },
  dialogStatus:2,
  edmHeader:{
    source:'control',
    nowTab:"首页",
    partition:""
  },
  // navigation 数据
  navigationList:[],
  homeMyToDoListTotal:0,
  siteName:"",
  siteLogo:"",
}

const mutations = {
  SET_DIALOGSTAT:(state,type) => {
    state.dialogStatus = type;
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_SCREEN:(state,{width,height})=>{
    state.screen.width = width;
    state.screen.height = height;
  },
  SET_SOURCE:(state,obj) => {
    state.edmHeader = obj;
  },
  SET_NAVIGATION:(state,data) => {
    let item = state.navigationList.find(v => v.name === data.name && v.url === data.url);
    let navArr = state.navigationList.filter(v => v.parentId === data.parentId);
    if (navArr) {
      navArr.forEach(v => delete v.isEnd);
    }
    if (!item) {
      data.isEnd = true;
      state.navigationList.push(data);
    } else {
      item.isEnd = true;
      item.isAct = true;
    }
  },
  DEL_NAVIGATION:(state,data) => {
    let i = state.navigationList.findIndex(v => v.name === data.name);
    if (i > -1){
      state.navigationList.splice(i,1);
    }
  },
  SET_HOMEMYTODOLISTTOTAL: (state, total) => {
    state.homeMyToDoListTotal = total
  },
  GET_SITECONFIG:(state,{siteName,siteLogo}) => {
    state.siteName = siteName;
    state.siteLogo = siteLogo;
    console.log(siteName, siteLogo);
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setScreen({ commit }, {width,height}){
    commit('SET_SCREEN', {width,height})
  },
  setSource({commit},obj) {
    commit("SET_SOURCE",obj)
  },
  setNavigation({commit},obj) {
    commit("SET_NAVIGATION",obj);
  },
  delNavigation({commit},obj) {
    commit("DEL_NAVIGATION",obj);
  },
  getSiteConfig({commit}) {
    querySiteConfig().then(({ data }) => {
      commit("GET_SITECONFIG",{
        siteName:data.site_name.value,
        siteLogo:data.site_logo.value,
      });
    localStorage.setItem("siteName", data.site_name.value);
    localStorage.setItem("siteLogo", data.site_logo.value);
    });
  },
  setSiteName({commit,dispatch}, name) {
    return new Promise((resolve) => {
      updateSiteName(name).then((res) => {
        dispatch('getSiteConfig')
        resolve(res)
      });
    })
  },
  //当页面刷新时，重新给vuex赋值
  loadLocalSiteInfo({ commit }) {
    const siteName = localStorage.getItem('siteName')
    const siteLogo = localStorage.getItem('siteLogo')
    if (siteName && siteLogo) commit('GET_SITECONFIG', {
      siteName,
      siteLogo,
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
